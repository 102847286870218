<template>
	<div class="container">
		<div class="handle" style="display:flex;margin:10px 0;">
			<el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
				end-placeholder="结束日期" :shortcuts="shortcuts" @change="dateChange"></el-date-picker>
			<el-input placeholder="请输入用户昵称或账号名" v-model="query.nick_name" style="margin-left: 5px;width:30%;">
			</el-input>
			<el-button icon="el-icon-search" @click="goSearch"></el-button>
		</div>
		<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
			<el-table-column prop="nick_name" label="昵称" align="center"></el-table-column>
			<el-table-column label="头像" align="center">
				<template #default="scope">
					<el-image :src="filterCosImage(scope.row.head_img)" fit="cover" style="width: 100px;height: 100px; border-radius:50%">
					</el-image>
				</template>
			</el-table-column>
			<el-table-column prop="updated_at" label="修改日期"></el-table-column>
			<el-table-column prop="audit" label="审核人" v-if="type!=0"></el-table-column>
			<el-table-column prop="audit_time" label="审核时间" v-if="type!=0"></el-table-column>
			<el-table-column prop="reason" label="审核失败原因" v-if="type==2"></el-table-column>
			<el-table-column label="操作" width="180" align="center" v-if="type==0">
				<template #default="scope">
					<el-button type="text" icon="el-icon-edit" @click="handle(scope.$index, scope.row)">操作</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination background layout="total, prev, pager, next" :current-page="query.page"
				:page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
		</div>
		<el-dialog title="审核" v-model="show_detail" width="50%">
			<!-- <component :is="currentTabComponent" v-loading="detailLoading" :detail="detail"></component> -->
			<el-image :src="filterCosImage(current_item.head_img)" fit="scale-down" style="width: 100%;"></el-image>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="show_fail = true;">审核不通过</el-button>
					<el-button type="primary" @click="confirm">审核通过</el-button>
				</span>
			</template>
		</el-dialog>
		<el-dialog title="审核不通过" v-model="show_fail" width="50%">
			<el-select v-model="reason_id" placeholder="请选择类型" clearable @change="resonChange">
				<el-option v-for="item in reason_options" :key="item.id" :label="item.title" :value="item.id">
				</el-option>
			</el-select>
			<el-input v-model="reson_text" maxlength="20" placeholder="请输入原因" v-if="reason_id===0" />
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="show_fail = false;">取消</el-button>
					<el-button type="primary" @click="handleFail">确定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import config from '@/utils/config.js';
	import { getCount, filterCosImage } from '@/utils/common.js'
	export default {
		props: ['type', 'activeType'],
		watch: {
			activeType(val) {
				console.log(val, this.type)
				if (val == this.type) {
					this.getTableData();
				}
			},
			show_fail(val) {
				if (!val) {
					this.reson_text = ""
					this.reason_id = ""
				}
			}
		},
		data() {
			return {
				tableData: [],
				pageTotal: 0,
				defaultUrl: config.defaultUrl,
				query: {
					page: 1,
					limit: 20,
					status: this.type,
					nick_name: '',
					start_time: '',
					end_time: ''
				},
				reson_text: '',
				reason_id: '',
				reason: '',
				reason_options: [{
						title: '其它',
						id: 0
					}
				],
				loading: false,
				date: [],
				show_detail: false,
				show_fail: false,
				current_item: '', //选中项
				current_index: '', //选中项下标
				detailLoading: false,
				detail: '',
				shortcuts: [{
					text: '最近一周',
					value: (() => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						return [start, end]
					})()
				}, {
					text: '最近一个月',
					value: (() => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						return [start, end]
					})()
				}, {
					text: '最近三个月',
					value: (() => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						return [start, end]
					})()
				}],
			}

		},
		created() {
			this.getTableData()
			this.getReason()
		},
		methods: {
			filterCosImage,
			// 分页导航
			handlePageChange(val) {
				this.query.page = val
				this.getTableData();
			},
			resonChange(e) {
				console.log(e)
				if (e == 5) {
					this.reason = ""
				} else {
					for (let i in this.reason_options) {
						if (this.reason_options[i].id == e) {
							this.reason = this.reason_options[i].title
						}
					}

				}
			},
			goSearch() {
				this.getTableData()
			},
			getTableData() {
				// 获取列表
				this.loading = true;
				this.$axios({
					url: 'admin/user/headList',
					method: 'post',
					data: {
						...this.query
					},
				}).then((res) => {
					this.loading = false;
					if (res.code == 200) {
						// console.log(data)
						this.tableData = res.data.data;
						this.pageTotal = res.data.total;
					}
				}).catch(err => {
					this.loading = false;
					console.log(err)
				});
			},
			// 更改时间
			dateChange(e) {
				if (e) {
					this.query.start_time = this.date[0].getTime() / 1000
					this.query.end_time = this.date[1].getTime() / 1000
				} else {
					this.query.start_time = ''
					this.query.end_time = ''
				}
				console.log(this.query)
			},
			handle(index, item) {
				this.show_detail = true
				this.current_index = index
				this.current_item = item
			},
			confirm() {
				this.audit(1)
			},
			handleFail() {
				if (this.reason_id === 0 ) this.reason = this.reson_text
				this.audit(2, this.reason)
			},
			audit(status, reason) {
				// 审核
				this.$axios({
					url: 'admin/user/headAudit',
					method: 'post',
					data: {
						id: this.current_item.id,
						status,
						reason
					},
				}).then((res) => {
					if (res.code == 200) {
						this.tableData.splice(this.current_index, 1)
						this.show_detail = false
						this.show_fail = false
						this.$message.success('操作成功')
						getCount()
					}
				}).catch(err => {
					console.log(err)
				});
			},
			getReason() {
				this.$axios({
					url: 'admin/reason/getSelect',
					method: 'get',
					params: {
						limit: 100,
						type: 2
					},
				}).then((res) => {
					if (res.code == 200) {
						this.reason_options = res.data.concat(this.reason_options)
					}
				}).catch(err => {
					console.log(err)
				});
			}
		}
	}
</script>

<style>

</style>
